import './app/custom.scss';
import './app/theme.scss';
import '@synisys/idm-ui-shell/app/modules/shell-container/shell.container.component.css'
import '@synisys/idm-ng-controls/lib/kendo/styles/kendo.common-material.min.css'
import '@synisys/idm-ng-controls/lib/kendo/styles/kendo.rtl.min.css'
import '@synisys/idm-ng-controls/lib/kendo/styles/kendo.material.min.css'
import '@synisys/idm-ng-controls/lib/kendo/styles/kendo.material.mobile.min.css'
import '@synisys/bgd-mis-de-ui/app/custom.css'
import 'dragula/dist/dragula.css'

import 'core-js/client/shim.min.js';
import "reflect-metadata/Reflect.js"


import * as Hammer from 'hammerjs';

window.Hammer = Hammer;
